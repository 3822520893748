<template>
  <div>
    <a-carousel :style="{  height: '360px', }" indicator-type="line" :auto-play="false" trigger="hover"
      animation-name="slide" show-arrow="never">
      <!-- <a-carousel-item v-for="(item, index) in carouselList" :key="index">
        <div class="banner" :style="{ backgroundImage: 'url(/_fid/' + item.image + ')' }" @click="banerRouter(item)">
          <div v-if="item.content" class="wrapper" style="height: 360px; display: flex; align-items: center;">
            <div class="banner-container cursor">
              <div class="banner-container-title">{{item.title}}</div>
              <div class="banner-container-content line-text-3">{{item.content}}</div>
              <div class="banner-container-btn">加入学习</div>
            </div>
          </div>
        </div>
      </a-carousel-item> -->
      <a-carousel-item>
        <div class="banner">
          <img src="../assets/images/banner.png" alt="" style="height: 100%;width: 100%;" />
        </div>
      </a-carousel-item>
    </a-carousel>
    <div class="bg" style="padding-top: 0;">
      <div class="bg-img">
        教育平台，是公司倾力打造的在线实用技能学习平台，该平台于2022年10月底正式上线，主要为学习者提供海量、优质的课程，课程结构严谨，用户可以根据自身的学习程度，自主安排学习进度。平台的宗旨是，为每一位想真真正正学到些实用知识、技能的学习者，提供贴心的一站式学习服务。
      </div>
      <New :listLeft="listLeft" :titleLeft="'新闻公告'" :moreLeft="moreLeft" :moreRight="moreRight" :tab="tab"
        :listRight="listRight" :titleRight="'培训活动'" />
    </div>
    <!-- 专题专栏 -->
    <div class="bg">
      <div class="wrapper">
        <div>
          <Title :title="'专题专栏'" />
          <div _ngcontent-c5="" class="ztzl-list">
            <ul _ngcontent-c5="">
              <!-- jtother -->
              <li _ngcontent-c5="" @click="nextZtzl(1)"><img _ngcontent-c5="" alt="" src="../assets/images/djt/ztzl1.jpg">
              </li>
              <li _ngcontent-c5="" @click="nextZtzl(2)"><img _ngcontent-c5="" alt="" src="../assets/images/djt/ztzl2.jpg">
              </li>
              <li _ngcontent-c5="" @click="nextZtzl(3)"><img _ngcontent-c5="" alt="" src="../assets/images/djt/ztzl3.jpg">
              </li>
              <li _ngcontent-c5="" @click="nextZtzl(4)"><img _ngcontent-c5="" alt="" src="../assets/images/djt/ztzl4.jpg">
              </li>
              <!-- trainbase -->
              <li _ngcontent-c5="" @click="nextZtzl(5)"><img _ngcontent-c5="" alt="" src="../assets/images/djt/ztzl5.jpg">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 认证培训 -->
    <!-- <div class="bg">
      <div class="wrapper">
        <div>
          <Title :title="'认证培训'" :more="'更多'" @onClickMore="next(1)" />
          <div _ngcontent-c5="" class="authen-banner">
            <a _ngcontent-c5="" href="/authen">
              <img _ngcontent-c5="" alt="" src="../assets/images/djt/rzpx1645602661741.png">
            </a>
          </div>
          <div _ngcontent-c5="" class="authen-list">
            <ul _ngcontent-c5="">
              <li _ngcontent-c5="" v-for="(item, index) in rzpxList.slice(0,3)" :key="index">
                <a _ngcontent-c5="" href="/authen">
                  <img _ngcontent-c5="" alt="" :src="`/_fid/` + item.image">
                </a>
                <div _ngcontent-c5="" class="authen-list-img-model">{{item.title}}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 政策解读 -->
    <!-- <div class="bg">
      <div class="wrapper">
        <div>
          <Title :title="'政策解读'" :more="'更多'" @onClickMore="next(2)" />
          <div _ngcontent-c5="" class="train-list clearfix">
            <ul _ngcontent-c5="">
              <li _ngcontent-c5="" v-for="(item, index) in zcjdList.slice(0,4)" :key="index">
                <a _ngcontent-c5="" href="/policy">
                  <img _ngcontent-c5="" alt="" :src="`/_fid/` + item.image">
                  <p _ngcontent-c5="" class="train-name">{{item.title}}</p>
                  <p _ngcontent-c5="" class="train-detail">查看详情</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 近期开班 -->
    <!-- <div class="bg">
      <div class="wrapper">
        <div>
          <Title :title="'近期开班'" :more="'更多'" @onClickMore="onClickMore" />
          <div style="margin-top: 20px;">
            <Course :list="courseList" />
          </div>
        </div>
      </div>
    </div>
    <div style="background: #F7F6FA">
      <div class="wrapper">
        <img src="../assets/images/ad.jpg" alt="">
      </div>
    </div> -->
    <!-- 热门课程 -->
    <div class="bg">
      <div class="wrapper">
        <div>
          <Title :title="'热门课程'" :more="'更多'" @onClickMore="onClickMore" />
          <div style="margin-top: 20px;">
            <Course :list="courseList" />
          </div>
        </div>
      </div>
    </div>
    <div style="background: #F7F6FA">
      <div class="wrapper">
        <img src="../assets/images/ad.jpg" alt="">
      </div>
    </div>
    <!-- 培训服务 -->
    <div class="bg">
      <div class="wrapper">
        <div>
          <Title :title="'培训服务'" />
          <div _ngcontent-c5="" class="pxfw-list">
            <ul _ngcontent-c5="">
              <li _ngcontent-c5="" @click="nextPxfw(1)" ><img _ngcontent-c5="" alt="" src="../assets/images/djt/pxfw6.jpg"></li>
              <li _ngcontent-c5="" @click="nextPxfw(2)"><img _ngcontent-c5="" alt="" src="../assets/images/djt/pxfw7.jpg"></li>
              <li _ngcontent-c5="" @click="nextPxfw(3)"><img _ngcontent-c5="" alt="" src="../assets/images/djt/pxfw8.jpg"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'

import New from '@/components/new/new.vue'
import Title from '@/components/title/title.vue'
import Course from '@/components/course/course.vue'

import { getSwiperList, getArticleList } from "@/api/common.js"
import { courseList } from '@/api/course';

export default {
  components: { New, Title, Course },
  setup(props) {
    const router = useRouter()
    const state = reactive({
      rzpxList: [],
      zcjdList: [],
      // 轮播
      carouselList: [],
      // 新闻
      listLeft: [],
      titleLeft: '新闻公告',
      moreLeft: '更多动态',
      listRight: [],
      titleRight: '培训活动',
      moreRight: '更多动态',
      tab: [
        '标准计量资讯', '创新基地动态', '成员单位新闻'
      ],
      courseList: []
    })
    const onClickMore = (val) => {
      router.push({ path: '/course' })
    }
    // 更多
    const next = type => {
      if (type == 1) return router.push({ path: '/authen' })
      if (type == 2) return router.push({ path: '/policy' })
    }
    // 跳转专题专栏
    const nextZtzl = (type) => {
      // if (type == 5) return router.push({ path: '/trainbase' })
      // router.push({ path: '/jtother/detail' + type })
    }
    // 跳转培训服务
    const nextPxfw = (type) => {
      // router.push({ path: '/jtserve/detail' + type })
    }
    // 课程
    const getList = () => {
      courseList({ current: 1, size: 8 }).then(res => {
        if (res.code == 20000) {
          state.courseList = res.result.records
        }
      })
    }
    getList()

    const banerRouter = (e) => {
      if (e.url) {
        window.open(e.url, '_blank')
      }
    }
    // 新闻
    const getArticle = (cmsTypeId) => {
      return getArticleList({ current: 1, size: 10, status: 2, cmsTypeId })
    }
    // 新闻公告
    getArticle(1).then(res => {
      if (res.code === 20000) {
        state.listLeft = res.result.records
      }
      console.log(res)
    })
    // 培训活动
    getArticle(2).then(res => {
      if (res.code === 20000) {
        state.listRight = res.result.records
      }
    })
    // 政策解读
    // getArticle(3).then(res => {
    //   if (res.code === 20000) {
    //     state.zcjdList = res.result.records
    //   }
    // })
    // // 认证培训解读
    // getArticle(4).then(res => {
    //   if (res.code === 20000) {
    //     state.rzpxList = res.result.records
    //   }
    // })

    // 轮播
    const getSwiper = () => {
      getSwiperList({ propertyType: '' }).then(res => {
        if (res.code === 20000) {
          state.carouselList = res.result
        }
      })
    }
    getSwiper()
    return {
      ...toRefs(state),
      onClickMore,
      banerRouter,
      nextPxfw,
      nextZtzl,
      next
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(vm)
    })
  }
}
</script>

<style lang="less" scoped>
.bg-img {
  height: 200px;
  width: 1200px;
  margin: auto;
  background-image: url('../assets/images/img_a.png');
  background-size: 100% 100%;
  padding: 50px 150px 0 150px;
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 15px;
}
.pxfw-list[_ngcontent-c5]{
  margin: 27px 0;
}
.pxfw-list[_ngcontent-c5]   ul[_ngcontent-c5]{
  display: flex;
  justify-content: space-evenly;
}
.pxfw-list[_ngcontent-c5]   ul[_ngcontent-c5]   li[_ngcontent-c5]{
  cursor: pointer;
  width: 230px;
  height: 90px;
  transition: all 0.2s linear;
}
.pxfw-list[_ngcontent-c5]   ul[_ngcontent-c5]   li[_ngcontent-c5]:hover{
  box-shadow: 0 0 15px #aaa;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.pxfw-list[_ngcontent-c5]   ul[_ngcontent-c5]   li[_ngcontent-c5]   img[_ngcontent-c5]{
  width: 100%;
  height: 100%;
}
.train-list[_ngcontent-c5]{width:100%;margin-top: 23px}
.train-list[_ngcontent-c5]   li[_ngcontent-c5]{width:292px;height:260px;float: left;padding: 4px;margin-right: 10px; box-sizing: border-box;}
.train-list[_ngcontent-c5]   li[_ngcontent-c5]:nth-last-child(1){margin-right: 0;}
.train-list[_ngcontent-c5]   li[_ngcontent-c5]   img[_ngcontent-c5]{width:284px;height:159px;}

.train-name[_ngcontent-c5]{
  font-size: 14px;
  color: #666;
  width:100%;
  overflow: hidden;
  height: 58px;
  line-height: 24px;
  display: block;
  margin-top: 5px;
  margin-bottom: 2px;
  padding: 5px 0px 0;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.train-price[_ngcontent-c5]{font-size: 14px;float: left;color: red;}
.train-detail[_ngcontent-c5]{font-size: 14px;float: right;padding-right: 8px;color: #217aff}
.train-list[_ngcontent-c5]   li[_ngcontent-c5]:hover{box-shadow: 0 0  25px #aaa;}

.ztzl-list[_ngcontent-c5] {
  margin-top: 27px;
}

.ztzl-list[_ngcontent-c5] ul[_ngcontent-c5] {
  display: flex;
  justify-content: space-between;
}

.ztzl-list[_ngcontent-c5] ul[_ngcontent-c5] li[_ngcontent-c5] {
  cursor: pointer;
  width: 230px;
  height: 90px;
  transition: all 0.2s linear;
}

.ztzl-list[_ngcontent-c5] ul[_ngcontent-c5] li[_ngcontent-c5]:hover {
  box-shadow: 0 0 15px #aaa;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.ztzl-list[_ngcontent-c5] ul[_ngcontent-c5] li[_ngcontent-c5] img[_ngcontent-c5] {
  width: 100%;
  height: 100%;
}

.authen-banner[_ngcontent-c5] {
  width: 100%;
  margin-top: 27px;
  height: 115px;
}

.authen-banner[_ngcontent-c5] img[_ngcontent-c5] {
  width: 100%;
  height: 115px;
}

.authen-list[_ngcontent-c5] {
  overflow: hidden;
}

.authen-list[_ngcontent-c5] li[_ngcontent-c5] {
  width: 390px;
  height: 218px;
  margin-right: 15px;
  margin-top: 14px;
  float: left;
  position: relative;
}

.authen-list[_ngcontent-c5] li[_ngcontent-c5] a[_ngcontent-c5] {
  display: block;
  width: 100%;
  height: 100%;
}

.authen-list[_ngcontent-c5] li[_ngcontent-c5] img[_ngcontent-c5] {
  width: 100%;
  height: 100%;
}

.authen-list[_ngcontent-c5] li[_ngcontent-c5]:nth-last-child(1) {
  margin-right: 0;
}

.authen-list-img-model[_ngcontent-c5] {
  width: 100%;
  height: 34px;
  background-color: rgba(0, 0, 0, .4);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  color: rgba(255, 255, 255, .5);
  font-size: 14px;
  line-height: 34px;
  padding-left: 10px;
}

.authen-list[_ngcontent-c5] li[_ngcontent-c5]:hover {
  box-shadow: 0 0 25px #aaa;
}
</style>


<style lang="less" scoped>
.bg {
  background: #F7F6FA;
  padding: 20px 0;
}

.banner {
  height: 360px;
  background: no-repeat center;

  .banner-container {
    margin-left: 50px;
    width: 420px;
    height: 253px;
    background: rgba(255, 255, 255, 0.9000);
    border-radius: 4px;
    padding: 24px;
    bottom: border-box;

    .banner-container-title {
      font-size: 24px;
      font-weight: 500;
      color: #323233;
      height: 40px;
      line-height: 40px;
    }

    .banner-container-content {
      margin: 10px 0 20px;
      font-size: 14px;
      color: #323233;
      line-height: 28px;
      height: 84px;
    }

    .banner-container-btn {
      width: 145px;
      height: 48px;
      background-color: #4F17A8;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-size: 18px;
    }

    // align-items: center;
    // justify-content: center;
    // font-size: 40px;
  }
}
</style>
