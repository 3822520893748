<template>
  <div>
    <div class="wrapper new">
      <div class="new-left">
        <Title :title="titleLeft" :more="moreLeft" @onClickMore="onClickMore(1)" />
        <div class="new-container">
          <!-- <div class="tab">
            <div class="tab-pane" @click="handleTrigger(index)" :class="index == active ? 'active': ''" v-for="(item, index) in tab" :key="index">{{item}}</div>
          </div> -->
          <div class="tab-container">
            <div v-for="(item, index) in listLeft.slice(0,6)" :key="index" >
              <div v-if="index == 0" class="floor-t">
                <div class="floor-t-img">
                  <img :src="'/_fid/' + item.image" alt="">
                </div>
                <div class="floor-t-container">
                  <div class="floor-t-title line-text-1" @click="onClickDtl(1, item.id)">{{item.title}}</div>
                  <div class="floor-t-text line-text-3">{{item.emphasis}}</div>
                  <div class="floor-t-p">{{item.publishTime}}</div>
                </div>
              </div>
              <div v-else >
                <div class="floor-b" @click="onClickDtl(1, item.id)">
                  <div class="floor-b-left line-text-1">{{item.title}}</div>
                  <div class="floor-b-right">{{item.publishTime}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="new-right">
        <Title :title="titleRight" :more="moreRight" @onClickMore="onClickMore(2)" />
        <div class="new-container" >
          <div v-if="flag">
            <img style="width: 100%; height: 170px; border-radius: 6px 6px 0 0;" src="../../assets/images/01.png" alt="">
            <div class="tab-container" style="padding: 6px 24px 0;">
              <div class="floor-b" v-for="(item, index) in listRight.slice(0,7)" :key="index" @click="onClickDtl(2, item.id)">
                <div class="floor-b-left line-text-1">{{item.contentPure}}</div>
                <div class="floor-b-right">{{item.publishTime}}</div>
              </div>
            </div>
          </div>
          <div class="tab-container" v-else>
            <div class="floor-b" v-for="(item, index) in listRight.slice(0,10)" :key="index" @click="onClickDtl(2, item.id)">
              <div class="floor-b-left line-text-1">{{item.title}}</div>
              <div class="floor-b-right">{{item.publishTime}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'

import Title from '@/components/title/title.vue'
export default {
  components: { Title },
  props: {
    listLeft: { type: Array },
    listRight: { type: Array },
    tab: { type: Array },
    titleLeft: { type: String },
    moreLeft: { type: String },
    titleRight: { type: String },
    moreRight: { type: String },
    flag: { type: Boolean }
  },
  setup(props) {
    const router = useRouter()
    const state = reactive({
      active: 0
    })
    const handleTrigger = (index) => {
      state.active = index
    }
    const onClickMore = (val) => {
      router.push({ path: '/new/list' + val })
    }
    const onClickDtl = (val, id) => {
      const newpage = router.resolve({ path: '/new/detail', query: { cmsTypeId: val, id} })
      window.open(newpage.href, '_blank')
    }
    return {
      ...toRefs(state),
      handleTrigger,
      onClickMore,
      onClickDtl
    }
  }
}
</script>

<style lang="less" scoped>
.new {
  display: flex;
  .new-left {
    width: 800px;
    margin-right: 20px;
  }
  .new-right {
    flex: 1;
  }
  .new-container {
    margin-top: 15px;
    background: #FFFFFF;
    border-radius: 4px;
    height: 420px;
  }

}
.tab {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e9e9e9;
  .tab-pane {
    display: flex;
    align-items: center;
    margin: 0 24px;
    height: 60px;
    color: #474747;
    cursor: pointer;
    border-bottom: 3px solid transparent;
  }
  .active {
    color: #207AFF;
    font-weight: 600;
    border-bottom: 3px solid #207AFF;
  }
}
.tab-container {
  padding: 24px;
  .floor-t {
    display: flex;
    margin-bottom: 15px;
    .floor-t-img {
      width: 330px;
      height: 174px;
      border-radius: 4px;
      margin-right: 22px;
      cursor: pointer;
      img {
        width: 330px;
        height: 174px;
        border-radius: 4px;
      }
    }
    .floor-t-container {
      flex: 1;
      position: relative;
      .floor-t-title::before {
        position: absolute;
        left: 0px;
        top: 12px;
        content: '';
        height: 18px;
        width: 3px;
        background-color: #FF9B3C;
        border-radius: 2px;
      }
      .floor-t-title:hover {
        color: #096DD9;
        cursor: pointer;
      }
      .floor-t-title {
        color: #474747;
        font-size: 16px;
        font-weight: 500;
        line-height: 40px;
        border-bottom: 1px solid #e9e9e9;
        padding: 0 14px;
        position: relative;
      }
      .floor-t-text,.floor-t-p {
        margin-top: 10px;
        font-size: 12px;
        color: #999999;
        line-height: 20px;
        padding: 0 4px;
      }
      .floor-t-p {
        position: absolute;
        bottom: 10px;
        left: 0;
      }
    }
  }
  .floor-b::before {
    position: absolute;
    content: '';
    left: 0;
    top: 16px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #D8D8D8;
  }
  .floor-b {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
    font-size: 14px;
    color: #474747;
    height: 38px;
    border-bottom: 1px #E9E9E9 dashed;
    .floor-b-left:hover {
      color: #096DD9;
    }
    .floor-b-left {
      flex: 1;
      cursor: pointer;
    }
    .floor-b-right {
      font-size: 12px;
      color: #999999;
      margin-left: 20px;
    }
  }
}

</style>
