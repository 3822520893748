<template>
  <div>
    <div class="title">
      <div style="flex: 1;">{{title}}</div>
      <div class="r" v-if="more" @click="handleMore">
        <span>{{more}}</span>
        <icon-right-circle style="color: #FF761A; font-size: 20px; margin-left: 6px;"></icon-right-circle>
      </div>
    </div>
  </div>
</template>

<script>
// import { emit } from 'process'
// import { reactive, toRefs, watch } from 'vue'
export default {
  props: {
    title: { type: String },
    more: { type: String }
  },
  setup(props, { emit, attrs, slots }) {
    const handleMore = () => {
      emit('onClickMore', 'click')
    }
    return {
      handleMore
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  position: relative;
  height: 40px;
  // padding: 0 10px 0 20px;
  font-size: 24px;
  color: #323233;
  display: flex;
  align-items: center;
  padding-left: 20px;
  .r {
    cursor: pointer;
    color: #3F4359;
    font-size: 14px;
     display: flex;
    align-items: center;
  }
}
.title::before {
  position: absolute;
  left: 0;
  top: 9px;
  content: '';
  height: 24px;
  width: 4px;
  background-color: #4F17A8;
  border-radius: 2px;
}
</style>
